<template>
	<div>
		<div>
			<h4>
				Vyberte si
				<span v-if="isCourse || isLevelUp">kurz</span>
				<span v-if="isCamp">
					tábor </span
				>:
			</h4>
      <p v-if="type === 'gamecraft-na-skolach'">(V prihláške aktuálne nemáme zverejnené všetky školy, postupne ich pridávame)</p>
			<div class="row mb-5 products-row">
				<div v-for="c in products" :key="c.id" class="col-lg-4 mb-3 pr-0" @click="onProductSelected(c)">
					<z-card :selected="c.id == (selectedProduct && selectedProduct.id)" class="z-clickable h-100">
						<h5 v-if="isOnlineCourse" class="mb-2">{{ c.name }}</h5>
						<div v-else-if="isLevelUp">
							<div>Kurz <z-tooltip v-if="c.description" :text="c.description" /></div>
							<h5 class="mb-2">{{ c.name }}</h5>
							<div v-if="c.age_range" class="z-small-text mb-1">
								<hr />
								{{ c.age_range }} rokov
							</div>
							<div>
								<b-badge variant="dark" class="px-2 py-1" v-for="town in places(c.product_periods)" :key="town">{{ town }}</b-badge>
							</div>
						</div>
						<div v-else>
							<div>Tábor <z-tooltip v-if="c.description" :text="c.description" /></div>
							<h5 class="mb-2">{{ c.name }}</h5>
							<div v-if="c.age_range" class="z-small-text mb-1">
								<hr />
								{{ c.age_range }} rokov
							</div>
							<div>
								<b-badge variant="dark" class="px-2 py-1" v-for="town in places(c.product_periods)" :key="town">{{ town }}</b-badge>
							</div>
						</div>
					</z-card>
				</div>
			</div>
		</div>

		<div v-if="selectedProduct">
			<h4>
				Vyberte si
				<span v-if="isCourse || isLevelUp">termín</span>
				<span v-if="isCamp">turnus</span>
				:
			</h4>
			<div class="row mb-5 periods-row">
				<a-product-period-card v-for="pp in selectedProduct.product_periods" :key="pp.id" :student-index="orderRegistration.fe_custom_data.student_index" :product-name="selectedProduct.name" :product-period="pp" @click="onProductPeriodSelected(pp)" />
				<div v-if="!selectedProduct.product_periods.length" class="ml-3">Ľutujeme, ale nie sú dostupné žiadne {{ isCourse || isLevelUp ? 'termíny' : 'turnusy' }}.</div>
			</div>
		</div>

		<div v-if="orderRegistration.product_period_id && orderRegistration.product_period.faculties.length">
			<h4 v-if="isLevelUp" id="select-faculty">
				V akej oblasti sa chcete rozvíjať?
			</h4>
			<h4 v-else id="select-faculty">
        <span v-if="type === 'vytvor-si-hru'">Je aj iný deň v týždni, kedy Vám účasť na kurze vyhovuje? (ako alternatíva)</span>
        <span v-else-if="type === 'gamecraft'">Vyberte si dĺžku lekcie a čas:</span>
        <span v-else-if="type === 'gamecraft-na-skolach'">Prosím zvoľte presný čas lekcie.</span>
        <span v-else>Vyberte si fakultu pre turnus {{ formatDate(orderRegistration.product_period.start_date, 'DD.MM') }} – {{ formatDate(orderRegistration.product_period.end_date) }}:</span>
      </h4>
			<div class="row mb-5 periods-row">
				<div v-for="f in orderRegistration.product_period.faculties" :key="f.id" class="col-lg-4 mb-3 pr-0" @click="$emit('faculty-selected', { faculty: f, productPeriodId: orderRegistration.product_period_id, studentIndex: orderRegistration.fe_custom_data.student_index })">
					<z-card :selected="f.id == orderRegistration.faculty_id" class="z-clickable h-100">
						<h5 class="mb-2">
							{{ f.name }}
							<z-tooltip v-if="f.description" :text="f.description" />
						</h5>
					</z-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	components: {
		'z-card': () => import('@/plugins/app@components/card/z-card.vue'),
		'z-tooltip': () => import('@/plugins/app@components/tooltip/z-tooltip.vue'),
		'a-product-period-card': () => import('@/plugins/app/order/components/a-product-period-card.vue')
	},

	props: ['orderRegistration'],

	data() {
		return {
			products: [],
			selectedProduct: null
		}
	},

	async mounted() {
		await this._loadProducts()
	},

	computed: {
		...mapGetters('order', ['orderRegistrationsForStudentIndex']),

		isCourse() {
			return productUtils.isCourse(this.type)
		},
		isCamp() {
			return productUtils.isCamp(this.type)
		},
		isOnlineCourse() {
			return productUtils.isOnlineCourse(this.type)
		},
		isLevelUp() {
			return productUtils.isLevelUp(this.type)
		},

		type() {
			return this.$route.params.type
		},

		selectedProductPeriod() {
			return this.selectedProduct.product_periods.find((pp) => pp.id == this.orderRegistration.product_period_id)
		}
	},

	methods: {
		onProductSelected(product) {
			this.$store.commit('order/resetedStudent', this.orderRegistration.fe_custom_data.student_index)
			this.selectedProduct = product
			this.$emit('product-selected', product)
		},

		onProductPeriodSelected(productPeriod) {
			this.$emit('product-period-selected', {
				...productPeriod,
				product: this.selectedProduct
			})
		},

		normalize(str) {
			return str
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
				.replace(/\s+/g, '-')
				.replace(':', '-')
				.replace('(', '')
				.replace(')', '')
		},

		places(productPeriods) {
			return productPeriods.map((pp) => pp.place.town).filter((town, index, self) => self.indexOf(town) == index)
		},

		async prefill() {
			const product = this.$route.query.produkt
			const productPeriod = this.$route.query.perioda
			const faculty = this.$route.query.fakulta
			if (product) {
				const firstFullProduct = this.products.filter((c) => c.id == product)[0]
				await this.onProductSelected(firstFullProduct)
			}
			if (product && productPeriod) {
				const period = this.selectedProduct.product_periods.filter((c) => c.id == productPeriod)[0]
				if (period) {
					this.onProductPeriodSelected(period)
				}
				if (period && faculty) {
					const selectedFaculty = period.faculties.filter((c) => c.id == faculty)[0]
					const productPeriodInfo = this.orderRegistrationsForStudentIndex(this.orderRegistration.fe_custom_data.student_index)
					this.$emit('faculty-selected', {
						faculty: selectedFaculty,
						productPeriodId: productPeriodInfo[0].product_period_id,
						studentIndex: productPeriodInfo[0].fe_custom_data.student_index
					})
				}
			}
		},

		formatDate(date, format = 'DD.MM.YYYY') {
			return moment(date).format(format)
		},

		async _loadProducts() {
			try {
				const response = await wAxios.get_data(`v1/products?type=${this.type}`)
				this.products = response.data.data
				this._determineSelectedProduct()
			} catch (error) {
				console.error(error)
			}
		},

		_determineSelectedProduct() {
			this.selectedProduct = this.products.find((p) => {
				if (!this.orderRegistration.product_period || !this.orderRegistration.product_period.product) return this.selectedProduct && this.selectedProduct.id == p.id
				return p.id == this.orderRegistration.product_period.product.id
			})
		}
	},

	watch: {
		orderRegistration: {
			deep: true,
			handler() {
				this._determineSelectedProduct()
			}
		},
		products() {
			if (this.$route.query.produkt) this.prefill()
		}
	}
}
</script>

<style lang="sass" scoped>
.periods-row,
.products-row
	max-width: 860px
	.z-clickable
		height: 100%
</style>
